/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'
import { Router } from '@reach/router'
import { useEffect, lazy, Suspense } from 'react'
import Preloader from './components/Preloader'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import CookieConsent from 'react-cookie-consent'
import Link from 'utilities/Link'
import cookies from './images/cookies.png'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Navigation = lazy(() =>
  import('components/Navigation'))
const Cookies = lazy(() =>
  import('screens/legal/Cookies'))
const TermsAndConditions = lazy(() =>
  import('screens/legal/TermsAndConditions'))
const PrivacyPolicy = lazy(() =>
  import('screens/legal/Privacy'))
const SignIn = lazy(() =>
  import('screens/sign-in/SignIn'))
const SignUp = lazy(() =>
  import('screens/sign-up/SignUp'))
const ResetPassword = lazy(() =>
  import('screens/password-reset/PasswordReset'))
const EmailVerified = lazy(() =>
  import('screens/EmailVerified'))
const FourOFour = lazy(() =>
  import('screens/FourOFour'))
const Dashboard = lazy(() =>
  import('screens/dashboard/Dashboard'))
const Leads = lazy(() =>
  import('screens/leads/Leads'))
const Areas = lazy(() =>
  import('screens/areas/Areas'))
const Invoices = lazy(() =>
  import('screens/invoices/Invoices'))
const MyAccount = lazy(() =>
  import('screens/myAccount/MyAccount'))
const BillingSettings = lazy(() =>
  import('screens/billings/BillingSettings'))
const LearnMore = lazy(() =>
  import('screens/LearnMore'))
const ConfirmationBilling = lazy(() =>
  import('screens/billings/confirmation'))
const Finished = lazy(() =>
  import('screens/landing/lead-registration/Finished'))
const Unsubscribed = lazy(() =>
  import('screens/EmailUnsubscribe'))
const Welcome = lazy(() =>
  import('screens/Welcome'))
const Contact = lazy(() =>
  import('screens/Contact'))
const WelcomeExpert = lazy(() =>
  import('screens/WelcomeExpert'))
const Profile = lazy(() =>
  import('screens/profile/Profile'))
const Appointment = lazy(() =>
  import('screens/Appointment'))
const NewLanding = lazy(() =>
  import('screens/NewLanding'))
const ExpertsLanding = lazy(() =>
  import('screens/sign-up/ExpertsLanding'))
const Pricing = lazy(() =>
  import('screens/Pricing'))
const Faq = lazy(() =>
  import('screens/FAQ'))
const FloorPlans = lazy(() =>
  import('screens/FloorPlans'))
const Settings = lazy(() =>
  import('screens/dashboard/Settings'))
const AccountSuspended = lazy(() =>
  import('screens/sign-in/AccountSuspended'))
// Landing page automatic generator routing !!!DON'T EDIT!!!
const Landing = lazy(() =>
  import('screens/landing-page-generator/Landing'))
const LandingBirmingham = lazy(() =>
  import('screens/landing-page-generator/LandingBirmingham'))
const LandingCoventry = lazy(() =>
  import('screens/landing-page-generator/LandingCoventry'))
const LandingLiverpool = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpool'))
const LandingNottingham = lazy(() =>
  import('screens/landing-page-generator/LandingNottingham'))
const LandingBolton = lazy(() =>
  import('screens/landing-page-generator/LandingBolton'))
const LandingDerby = lazy(() =>
  import('screens/landing-page-generator/LandingDerby'))
const LandingLondon = lazy(() =>
  import('screens/landing-page-generator/LandingLondon'))
const LandingPeterborough = lazy(() =>
  import('screens/landing-page-generator/LandingPeterborough'))
const LandingBradford = lazy(() =>
  import('screens/landing-page-generator/LandingBradford'))
const LandingEdinburgh = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburgh'))
const LandingManchester = lazy(() =>
  import('screens/landing-page-generator/LandingManchester'))
const LandingReading = lazy(() =>
  import('screens/landing-page-generator/LandingReading'))
const LandingBristol = lazy(() =>
  import('screens/landing-page-generator/LandingBristol'))
const LandingGlasgow = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgow'))
const LandingNewcastle = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastle'))
const LandingSheffield = lazy(() =>
  import('screens/landing-page-generator/LandingSheffield'))
const LandingCambridge = lazy(() =>
  import('screens/landing-page-generator/LandingCambridge'))
const LandingLeeds = lazy(() =>
  import('screens/landing-page-generator/LandingLeeds'))
const LandingNorthampton = lazy(() =>
  import('screens/landing-page-generator/LandingNorthampton'))
const LandingWolverhampton = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhampton'))
const LandingCardiff = lazy(() =>
  import('screens/landing-page-generator/LandingCardiff'))
const LandingLeicester = lazy(() =>
  import('screens/landing-page-generator/LandingLeicester'))
const LandingNorwich = lazy(() =>
  import('screens/landing-page-generator/LandingNorwich'))
const LandingArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingArchitect'))
const LandingBirminghamArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamArchitect'))
const LandingCoventryArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryArchitect'))
const LandingLiverpoolArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolArchitect'))
const LandingNottinghamArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamArchitect'))
const LandingBoltonArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonArchitect'))
const LandingDerbyArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyArchitect'))
const LandingLondonArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingLondonArchitect'))
const LandingPeterboroughArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughArchitect'))
const LandingBradfordArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordArchitect'))
const LandingEdinburghArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghArchitect'))
const LandingManchesterArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterArchitect'))
const LandingReadingArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingReadingArchitect'))
const LandingBristolArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingBristolArchitect'))
const LandingGlasgowArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowArchitect'))
const LandingNewcastleArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleArchitect'))
const LandingSheffieldArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldArchitect'))
const LandingCambridgeArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeArchitect'))
const LandingLeedsArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsArchitect'))
const LandingNorthamptonArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonArchitect'))
const LandingWolverhamptonArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonArchitect'))
const LandingCardiffArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffArchitect'))
const LandingLeicesterArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterArchitect'))
const LandingNorwichArchitect = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichArchitect'))
const LandingStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingStructuralEngineer'))
const LandingBirminghamStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamStructuralEngineer'))
const LandingCoventryStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryStructuralEngineer'))
const LandingLiverpoolStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolStructuralEngineer'))
const LandingNottinghamStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamStructuralEngineer'))
const LandingBoltonStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonStructuralEngineer'))
const LandingDerbyStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyStructuralEngineer'))
const LandingLondonStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingLondonStructuralEngineer'))
const LandingPeterboroughStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughStructuralEngineer'))
const LandingBradfordStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordStructuralEngineer'))
const LandingEdinburghStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghStructuralEngineer'))
const LandingManchesterStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterStructuralEngineer'))
const LandingReadingStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingReadingStructuralEngineer'))
const LandingBristolStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingBristolStructuralEngineer'))
const LandingGlasgowStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowStructuralEngineer'))
const LandingNewcastleStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleStructuralEngineer'))
const LandingSheffieldStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldStructuralEngineer'))
const LandingCambridgeStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeStructuralEngineer'))
const LandingLeedsStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsStructuralEngineer'))
const LandingNorthamptonStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonStructuralEngineer'))
const LandingWolverhamptonStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonStructuralEngineer'))
const LandingCardiffStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffStructuralEngineer'))
const LandingLeicesterStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterStructuralEngineer'))
const LandingNorwichStructuralEngineer = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichStructuralEngineer'))
const LandingPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingPropertySolicitor'))
const LandingBirminghamPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamPropertySolicitor'))
const LandingCoventryPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryPropertySolicitor'))
const LandingLiverpoolPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolPropertySolicitor'))
const LandingNottinghamPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamPropertySolicitor'))
const LandingBoltonPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonPropertySolicitor'))
const LandingDerbyPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyPropertySolicitor'))
const LandingLondonPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonPropertySolicitor'))
const LandingPeterboroughPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughPropertySolicitor'))
const LandingBradfordPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordPropertySolicitor'))
const LandingEdinburghPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghPropertySolicitor'))
const LandingManchesterPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterPropertySolicitor'))
const LandingReadingPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingPropertySolicitor'))
const LandingBristolPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolPropertySolicitor'))
const LandingGlasgowPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowPropertySolicitor'))
const LandingNewcastlePropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastlePropertySolicitor'))
const LandingSheffieldPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldPropertySolicitor'))
const LandingCambridgePropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgePropertySolicitor'))
const LandingLeedsPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsPropertySolicitor'))
const LandingNorthamptonPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonPropertySolicitor'))
const LandingWolverhamptonPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonPropertySolicitor'))
const LandingCardiffPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffPropertySolicitor'))
const LandingLeicesterPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterPropertySolicitor'))
const LandingNorwichPropertySolicitor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichPropertySolicitor'))
const LandingPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingPartyWallSurveyor'))
const LandingBirminghamPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamPartyWallSurveyor'))
const LandingCoventryPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryPartyWallSurveyor'))
const LandingLiverpoolPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolPartyWallSurveyor'))
const LandingNottinghamPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamPartyWallSurveyor'))
const LandingBoltonPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonPartyWallSurveyor'))
const LandingDerbyPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyPartyWallSurveyor'))
const LandingLondonPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonPartyWallSurveyor'))
const LandingPeterboroughPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughPartyWallSurveyor'))
const LandingBradfordPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordPartyWallSurveyor'))
const LandingEdinburghPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghPartyWallSurveyor'))
const LandingManchesterPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterPartyWallSurveyor'))
const LandingReadingPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingPartyWallSurveyor'))
const LandingBristolPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolPartyWallSurveyor'))
const LandingGlasgowPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowPartyWallSurveyor'))
const LandingNewcastlePartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastlePartyWallSurveyor'))
const LandingSheffieldPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldPartyWallSurveyor'))
const LandingCambridgePartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgePartyWallSurveyor'))
const LandingLeedsPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsPartyWallSurveyor'))
const LandingNorthamptonPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonPartyWallSurveyor'))
const LandingWolverhamptonPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonPartyWallSurveyor'))
const LandingCardiffPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffPartyWallSurveyor'))
const LandingLeicesterPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterPartyWallSurveyor'))
const LandingNorwichPartyWallSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichPartyWallSurveyor'))
const LandingInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingInteriorDesigner'))
const LandingBirminghamInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamInteriorDesigner'))
const LandingCoventryInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryInteriorDesigner'))
const LandingLiverpoolInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolInteriorDesigner'))
const LandingNottinghamInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamInteriorDesigner'))
const LandingBoltonInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonInteriorDesigner'))
const LandingDerbyInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyInteriorDesigner'))
const LandingLondonInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLondonInteriorDesigner'))
const LandingPeterboroughInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughInteriorDesigner'))
const LandingBradfordInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordInteriorDesigner'))
const LandingEdinburghInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghInteriorDesigner'))
const LandingManchesterInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterInteriorDesigner'))
const LandingReadingInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingReadingInteriorDesigner'))
const LandingBristolInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBristolInteriorDesigner'))
const LandingGlasgowInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowInteriorDesigner'))
const LandingNewcastleInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleInteriorDesigner'))
const LandingSheffieldInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldInteriorDesigner'))
const LandingCambridgeInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeInteriorDesigner'))
const LandingLeedsInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsInteriorDesigner'))
const LandingNorthamptonInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonInteriorDesigner'))
const LandingWolverhamptonInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonInteriorDesigner'))
const LandingCardiffInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffInteriorDesigner'))
const LandingLeicesterInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterInteriorDesigner'))
const LandingNorwichInteriorDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichInteriorDesigner'))
const LandingBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBuildingSurveyor'))
const LandingBirminghamBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamBuildingSurveyor'))
const LandingCoventryBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryBuildingSurveyor'))
const LandingLiverpoolBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolBuildingSurveyor'))
const LandingNottinghamBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamBuildingSurveyor'))
const LandingBoltonBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonBuildingSurveyor'))
const LandingDerbyBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyBuildingSurveyor'))
const LandingLondonBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonBuildingSurveyor'))
const LandingPeterboroughBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughBuildingSurveyor'))
const LandingBradfordBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordBuildingSurveyor'))
const LandingEdinburghBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghBuildingSurveyor'))
const LandingManchesterBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterBuildingSurveyor'))
const LandingReadingBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingBuildingSurveyor'))
const LandingBristolBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolBuildingSurveyor'))
const LandingGlasgowBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowBuildingSurveyor'))
const LandingNewcastleBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleBuildingSurveyor'))
const LandingSheffieldBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldBuildingSurveyor'))
const LandingCambridgeBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeBuildingSurveyor'))
const LandingLeedsBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsBuildingSurveyor'))
const LandingNorthamptonBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonBuildingSurveyor'))
const LandingWolverhamptonBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonBuildingSurveyor'))
const LandingCardiffBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffBuildingSurveyor'))
const LandingLeicesterBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterBuildingSurveyor'))
const LandingNorwichBuildingSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichBuildingSurveyor'))
const LandingQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingQuantitySurveyor'))
const LandingBirminghamQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamQuantitySurveyor'))
const LandingCoventryQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryQuantitySurveyor'))
const LandingLiverpoolQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolQuantitySurveyor'))
const LandingNottinghamQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamQuantitySurveyor'))
const LandingBoltonQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonQuantitySurveyor'))
const LandingDerbyQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyQuantitySurveyor'))
const LandingLondonQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonQuantitySurveyor'))
const LandingPeterboroughQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughQuantitySurveyor'))
const LandingBradfordQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordQuantitySurveyor'))
const LandingEdinburghQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghQuantitySurveyor'))
const LandingManchesterQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterQuantitySurveyor'))
const LandingReadingQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingQuantitySurveyor'))
const LandingBristolQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolQuantitySurveyor'))
const LandingGlasgowQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowQuantitySurveyor'))
const LandingNewcastleQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleQuantitySurveyor'))
const LandingSheffieldQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldQuantitySurveyor'))
const LandingCambridgeQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeQuantitySurveyor'))
const LandingLeedsQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsQuantitySurveyor'))
const LandingNorthamptonQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonQuantitySurveyor'))
const LandingWolverhamptonQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonQuantitySurveyor'))
const LandingCardiffQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffQuantitySurveyor'))
const LandingLeicesterQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterQuantitySurveyor'))
const LandingNorwichQuantitySurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichQuantitySurveyor'))
const LandingLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLandSurveyor'))
const LandingBirminghamLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamLandSurveyor'))
const LandingCoventryLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryLandSurveyor'))
const LandingLiverpoolLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolLandSurveyor'))
const LandingNottinghamLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamLandSurveyor'))
const LandingBoltonLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonLandSurveyor'))
const LandingDerbyLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyLandSurveyor'))
const LandingLondonLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonLandSurveyor'))
const LandingPeterboroughLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughLandSurveyor'))
const LandingBradfordLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordLandSurveyor'))
const LandingEdinburghLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghLandSurveyor'))
const LandingManchesterLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterLandSurveyor'))
const LandingReadingLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingLandSurveyor'))
const LandingBristolLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolLandSurveyor'))
const LandingGlasgowLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowLandSurveyor'))
const LandingNewcastleLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleLandSurveyor'))
const LandingSheffieldLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldLandSurveyor'))
const LandingCambridgeLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeLandSurveyor'))
const LandingLeedsLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsLandSurveyor'))
const LandingNorthamptonLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonLandSurveyor'))
const LandingWolverhamptonLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonLandSurveyor'))
const LandingCardiffLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffLandSurveyor'))
const LandingLeicesterLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterLandSurveyor'))
const LandingNorwichLandSurveyor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichLandSurveyor'))
const LandingTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingTownPlanner'))
const LandingBirminghamTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamTownPlanner'))
const LandingCoventryTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryTownPlanner'))
const LandingLiverpoolTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolTownPlanner'))
const LandingNottinghamTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamTownPlanner'))
const LandingBoltonTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonTownPlanner'))
const LandingDerbyTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyTownPlanner'))
const LandingLondonTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingLondonTownPlanner'))
const LandingPeterboroughTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughTownPlanner'))
const LandingBradfordTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordTownPlanner'))
const LandingEdinburghTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghTownPlanner'))
const LandingManchesterTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterTownPlanner'))
const LandingReadingTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingReadingTownPlanner'))
const LandingBristolTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingBristolTownPlanner'))
const LandingGlasgowTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowTownPlanner'))
const LandingNewcastleTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleTownPlanner'))
const LandingSheffieldTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldTownPlanner'))
const LandingCambridgeTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeTownPlanner'))
const LandingLeedsTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsTownPlanner'))
const LandingNorthamptonTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonTownPlanner'))
const LandingWolverhamptonTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonTownPlanner'))
const LandingCardiffTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffTownPlanner'))
const LandingLeicesterTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterTownPlanner'))
const LandingNorwichTownPlanner = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichTownPlanner'))
const LandingMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingMainContractor'))
const LandingBirminghamMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamMainContractor'))
const LandingCoventryMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryMainContractor'))
const LandingLiverpoolMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolMainContractor'))
const LandingNottinghamMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamMainContractor'))
const LandingBoltonMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonMainContractor'))
const LandingDerbyMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyMainContractor'))
const LandingLondonMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingLondonMainContractor'))
const LandingPeterboroughMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughMainContractor'))
const LandingBradfordMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordMainContractor'))
const LandingEdinburghMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghMainContractor'))
const LandingManchesterMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterMainContractor'))
const LandingReadingMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingReadingMainContractor'))
const LandingBristolMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingBristolMainContractor'))
const LandingGlasgowMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowMainContractor'))
const LandingNewcastleMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleMainContractor'))
const LandingSheffieldMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldMainContractor'))
const LandingCambridgeMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeMainContractor'))
const LandingLeedsMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsMainContractor'))
const LandingNorthamptonMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonMainContractor'))
const LandingWolverhamptonMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonMainContractor'))
const LandingCardiffMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffMainContractor'))
const LandingLeicesterMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterMainContractor'))
const LandingNorwichMainContractor = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichMainContractor'))
const LandingElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingElectricalWorks'))
const LandingBirminghamElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamElectricalWorks'))
const LandingCoventryElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryElectricalWorks'))
const LandingLiverpoolElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolElectricalWorks'))
const LandingNottinghamElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamElectricalWorks'))
const LandingBoltonElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonElectricalWorks'))
const LandingDerbyElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyElectricalWorks'))
const LandingLondonElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingLondonElectricalWorks'))
const LandingPeterboroughElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughElectricalWorks'))
const LandingBradfordElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordElectricalWorks'))
const LandingEdinburghElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghElectricalWorks'))
const LandingManchesterElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterElectricalWorks'))
const LandingReadingElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingReadingElectricalWorks'))
const LandingBristolElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingBristolElectricalWorks'))
const LandingGlasgowElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowElectricalWorks'))
const LandingNewcastleElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleElectricalWorks'))
const LandingSheffieldElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldElectricalWorks'))
const LandingCambridgeElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeElectricalWorks'))
const LandingLeedsElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsElectricalWorks'))
const LandingNorthamptonElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonElectricalWorks'))
const LandingWolverhamptonElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonElectricalWorks'))
const LandingCardiffElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffElectricalWorks'))
const LandingLeicesterElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterElectricalWorks'))
const LandingNorwichElectricalWorks = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichElectricalWorks'))
const LandingPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingPlumbing'))
const LandingBirminghamPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamPlumbing'))
const LandingCoventryPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryPlumbing'))
const LandingLiverpoolPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolPlumbing'))
const LandingNottinghamPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamPlumbing'))
const LandingBoltonPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonPlumbing'))
const LandingDerbyPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyPlumbing'))
const LandingLondonPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingLondonPlumbing'))
const LandingPeterboroughPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughPlumbing'))
const LandingBradfordPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordPlumbing'))
const LandingEdinburghPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghPlumbing'))
const LandingManchesterPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterPlumbing'))
const LandingReadingPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingReadingPlumbing'))
const LandingBristolPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingBristolPlumbing'))
const LandingGlasgowPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowPlumbing'))
const LandingNewcastlePlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastlePlumbing'))
const LandingSheffieldPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldPlumbing'))
const LandingCambridgePlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgePlumbing'))
const LandingLeedsPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsPlumbing'))
const LandingNorthamptonPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonPlumbing'))
const LandingWolverhamptonPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonPlumbing'))
const LandingCardiffPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffPlumbing'))
const LandingLeicesterPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterPlumbing'))
const LandingNorwichPlumbing = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichPlumbing'))
const LandingInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingInteriorDecorator'))
const LandingBirminghamInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamInteriorDecorator'))
const LandingCoventryInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryInteriorDecorator'))
const LandingLiverpoolInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolInteriorDecorator'))
const LandingNottinghamInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamInteriorDecorator'))
const LandingBoltonInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonInteriorDecorator'))
const LandingDerbyInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyInteriorDecorator'))
const LandingLondonInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingLondonInteriorDecorator'))
const LandingPeterboroughInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughInteriorDecorator'))
const LandingBradfordInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordInteriorDecorator'))
const LandingEdinburghInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghInteriorDecorator'))
const LandingManchesterInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterInteriorDecorator'))
const LandingReadingInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingReadingInteriorDecorator'))
const LandingBristolInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingBristolInteriorDecorator'))
const LandingGlasgowInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowInteriorDecorator'))
const LandingNewcastleInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleInteriorDecorator'))
const LandingSheffieldInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldInteriorDecorator'))
const LandingCambridgeInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeInteriorDecorator'))
const LandingLeedsInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsInteriorDecorator'))
const LandingNorthamptonInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonInteriorDecorator'))
const LandingWolverhamptonInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonInteriorDecorator'))
const LandingCardiffInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffInteriorDecorator'))
const LandingLeicesterInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterInteriorDecorator'))
const LandingNorwichInteriorDecorator = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichInteriorDecorator'))
const LandingSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingSoilInvestigation'))
const LandingBirminghamSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamSoilInvestigation'))
const LandingCoventrySoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingCoventrySoilInvestigation'))
const LandingLiverpoolSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolSoilInvestigation'))
const LandingNottinghamSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamSoilInvestigation'))
const LandingBoltonSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonSoilInvestigation'))
const LandingDerbySoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingDerbySoilInvestigation'))
const LandingLondonSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingLondonSoilInvestigation'))
const LandingPeterboroughSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughSoilInvestigation'))
const LandingBradfordSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordSoilInvestigation'))
const LandingEdinburghSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghSoilInvestigation'))
const LandingManchesterSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterSoilInvestigation'))
const LandingReadingSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingReadingSoilInvestigation'))
const LandingBristolSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingBristolSoilInvestigation'))
const LandingGlasgowSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowSoilInvestigation'))
const LandingNewcastleSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleSoilInvestigation'))
const LandingSheffieldSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldSoilInvestigation'))
const LandingCambridgeSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeSoilInvestigation'))
const LandingLeedsSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsSoilInvestigation'))
const LandingNorthamptonSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonSoilInvestigation'))
const LandingWolverhamptonSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonSoilInvestigation'))
const LandingCardiffSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffSoilInvestigation'))
const LandingLeicesterSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterSoilInvestigation'))
const LandingNorwichSoilInvestigation = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichSoilInvestigation'))
const LandingGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingGroundworks'))
const LandingBirminghamGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamGroundworks'))
const LandingCoventryGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryGroundworks'))
const LandingLiverpoolGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolGroundworks'))
const LandingNottinghamGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamGroundworks'))
const LandingBoltonGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonGroundworks'))
const LandingDerbyGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyGroundworks'))
const LandingLondonGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingLondonGroundworks'))
const LandingPeterboroughGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughGroundworks'))
const LandingBradfordGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordGroundworks'))
const LandingEdinburghGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghGroundworks'))
const LandingManchesterGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterGroundworks'))
const LandingReadingGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingReadingGroundworks'))
const LandingBristolGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingBristolGroundworks'))
const LandingGlasgowGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowGroundworks'))
const LandingNewcastleGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleGroundworks'))
const LandingSheffieldGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldGroundworks'))
const LandingCambridgeGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeGroundworks'))
const LandingLeedsGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsGroundworks'))
const LandingNorthamptonGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonGroundworks'))
const LandingWolverhamptonGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonGroundworks'))
const LandingCardiffGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffGroundworks'))
const LandingLeicesterGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterGroundworks'))
const LandingNorwichGroundworks = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichGroundworks'))
const LandingPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingPillingContractors'))
const LandingBirminghamPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamPillingContractors'))
const LandingCoventryPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryPillingContractors'))
const LandingLiverpoolPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolPillingContractors'))
const LandingNottinghamPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamPillingContractors'))
const LandingBoltonPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonPillingContractors'))
const LandingDerbyPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyPillingContractors'))
const LandingLondonPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingLondonPillingContractors'))
const LandingPeterboroughPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughPillingContractors'))
const LandingBradfordPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordPillingContractors'))
const LandingEdinburghPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghPillingContractors'))
const LandingManchesterPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterPillingContractors'))
const LandingReadingPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingReadingPillingContractors'))
const LandingBristolPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingBristolPillingContractors'))
const LandingGlasgowPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowPillingContractors'))
const LandingNewcastlePillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastlePillingContractors'))
const LandingSheffieldPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldPillingContractors'))
const LandingCambridgePillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgePillingContractors'))
const LandingLeedsPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsPillingContractors'))
const LandingNorthamptonPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonPillingContractors'))
const LandingWolverhamptonPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonPillingContractors'))
const LandingCardiffPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffPillingContractors'))
const LandingLeicesterPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterPillingContractors'))
const LandingNorwichPillingContractors = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichPillingContractors'))
const LandingCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingCctvAndDrainageSurveys'))
const LandingBirminghamCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamCctvAndDrainageSurveys'))
const LandingCoventryCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryCctvAndDrainageSurveys'))
const LandingLiverpoolCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolCctvAndDrainageSurveys'))
const LandingNottinghamCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamCctvAndDrainageSurveys'))
const LandingBoltonCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonCctvAndDrainageSurveys'))
const LandingDerbyCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyCctvAndDrainageSurveys'))
const LandingLondonCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingLondonCctvAndDrainageSurveys'))
const LandingPeterboroughCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughCctvAndDrainageSurveys'))
const LandingBradfordCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordCctvAndDrainageSurveys'))
const LandingEdinburghCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghCctvAndDrainageSurveys'))
const LandingManchesterCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterCctvAndDrainageSurveys'))
const LandingReadingCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingReadingCctvAndDrainageSurveys'))
const LandingBristolCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingBristolCctvAndDrainageSurveys'))
const LandingGlasgowCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowCctvAndDrainageSurveys'))
const LandingNewcastleCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleCctvAndDrainageSurveys'))
const LandingSheffieldCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldCctvAndDrainageSurveys'))
const LandingCambridgeCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeCctvAndDrainageSurveys'))
const LandingLeedsCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsCctvAndDrainageSurveys'))
const LandingNorthamptonCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonCctvAndDrainageSurveys'))
const LandingWolverhamptonCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonCctvAndDrainageSurveys'))
const LandingCardiffCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffCctvAndDrainageSurveys'))
const LandingLeicesterCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterCctvAndDrainageSurveys'))
const LandingNorwichCctvAndDrainageSurveys = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichCctvAndDrainageSurveys'))
const LandingKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingKitchenDesigner'))
const LandingBirminghamKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamKitchenDesigner'))
const LandingCoventryKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryKitchenDesigner'))
const LandingLiverpoolKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolKitchenDesigner'))
const LandingNottinghamKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamKitchenDesigner'))
const LandingBoltonKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonKitchenDesigner'))
const LandingDerbyKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyKitchenDesigner'))
const LandingLondonKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLondonKitchenDesigner'))
const LandingPeterboroughKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughKitchenDesigner'))
const LandingBradfordKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordKitchenDesigner'))
const LandingEdinburghKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghKitchenDesigner'))
const LandingManchesterKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterKitchenDesigner'))
const LandingReadingKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingReadingKitchenDesigner'))
const LandingBristolKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingBristolKitchenDesigner'))
const LandingGlasgowKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowKitchenDesigner'))
const LandingNewcastleKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleKitchenDesigner'))
const LandingSheffieldKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldKitchenDesigner'))
const LandingCambridgeKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeKitchenDesigner'))
const LandingLeedsKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsKitchenDesigner'))
const LandingNorthamptonKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonKitchenDesigner'))
const LandingWolverhamptonKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonKitchenDesigner'))
const LandingCardiffKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffKitchenDesigner'))
const LandingLeicesterKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterKitchenDesigner'))
const LandingNorwichKitchenDesigner = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichKitchenDesigner'))
const LandingLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingLandscapingServices'))
const LandingBirminghamLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingBirminghamLandscapingServices'))
const LandingCoventryLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingCoventryLandscapingServices'))
const LandingLiverpoolLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingLiverpoolLandscapingServices'))
const LandingNottinghamLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingNottinghamLandscapingServices'))
const LandingBoltonLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingBoltonLandscapingServices'))
const LandingDerbyLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingDerbyLandscapingServices'))
const LandingLondonLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingLondonLandscapingServices'))
const LandingPeterboroughLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingPeterboroughLandscapingServices'))
const LandingBradfordLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingBradfordLandscapingServices'))
const LandingEdinburghLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingEdinburghLandscapingServices'))
const LandingManchesterLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingManchesterLandscapingServices'))
const LandingReadingLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingReadingLandscapingServices'))
const LandingBristolLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingBristolLandscapingServices'))
const LandingGlasgowLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingGlasgowLandscapingServices'))
const LandingNewcastleLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingNewcastleLandscapingServices'))
const LandingSheffieldLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingSheffieldLandscapingServices'))
const LandingCambridgeLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingCambridgeLandscapingServices'))
const LandingLeedsLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingLeedsLandscapingServices'))
const LandingNorthamptonLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingNorthamptonLandscapingServices'))
const LandingWolverhamptonLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingWolverhamptonLandscapingServices'))
const LandingCardiffLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingCardiffLandscapingServices'))
const LandingLeicesterLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingLeicesterLandscapingServices'))
const LandingNorwichLandscapingServices = lazy(() =>
  import('screens/landing-page-generator/LandingNorwichLandscapingServices'))

// Mark of delete landings

const ClaimLead = lazy(() =>
  import('screens/claim-lead/ClaimLead'))
const LeadClaimed = lazy(() =>
  import('screens/claim-lead/LeadClaimed'))
const LeadAlreadyClaimed = lazy(() =>
  import('screens/claim-lead/LeadAlreadyClaimed'))
const LeadNoLeadsLeft = lazy(() =>
  import('screens/claim-lead/NoLeadsLeft'))
const LeadNoFreeLeadsLeft = lazy(() =>
  import('screens/claim-lead/NoFreeLeadsLeft'))
const LeadClaimError = lazy(() =>
  import('screens/claim-lead/Error'))
const DeleteProfile = lazy(() =>
  import('screens/DeleteProfile'))
const ClaimFreeLeads = lazy(() =>
  import('screens/claim-free-leads/ClaimFreeLeads'))
const FreeLeadsClaimed = lazy(() =>
  import('screens/claim-free-leads/FreeLeadsClaimed'))
const FreeLeadsAlreadyClaimed = lazy(() =>
  import('screens/claim-free-leads/FreeLeadsAlreadyClaimed'))
const FreeLeadsLeft = lazy(() =>
  import('screens/claim-free-leads/FreeLeadsLeft'))
const FreeLeadsError = lazy(() =>
  import('screens/claim-free-leads/FreeLeadsError'))
const NotInterestedClaim = lazy(() =>
  import('screens/claim-lead/NotInterestedClaim'))
function App () {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') { ReactGA.initialize('G-N9B920X7DC') }

  const theme = useSelector(state => state.theme)

  const pixelOptions = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false // enable logs
  }

  useEffect(() => {
    window.localStorage.setItem('leadEffect', true)

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      ReactPixel.init('810164750153189', pixelOptions)
      ReactPixel.pageView() // For tracking page view
    }
  }, [])

  useEffect(() => {
    document.body.style.backgroundColor =
         theme
           ? 'var(--lightColor)'
           : 'var(--darkColor)'
  }, [theme])

  return (
    <Suspense fallback={<Preloader />}>
      {process.env.REACT_APP_ENVIRONMENT === 'production' &&
        <Helmet
          meta={[
            {
              name: 'facebook-domain-verification',
              content: 'z42nm8txol9kueidh2n9207q4bce2k'
            }
          ]}
        />}
      <Navigation />
      <ToastContainer />
      <main>
        <Router>
          {/* Routing components */}
            <Landing path='/' />
            <LandingBirmingham path='/birmingham' />
            <LandingCoventry path='/coventry' />
            <LandingLiverpool path='/liverpool' />
            <LandingNottingham path='/nottingham' />
            <LandingBolton path='/bolton' />
            <LandingDerby path='/derby' />
            <LandingLondon path='/london' />
            <LandingPeterborough path='/peterborough' />
            <LandingBradford path='/bradford' />
            <LandingEdinburgh path='/edinburgh' />
            <LandingManchester path='/manchester' />
            <LandingReading path='/reading' />
            <LandingBristol path='/bristol' />
            <LandingGlasgow path='/glasgow' />
            <LandingNewcastle path='/newcastle' />
            <LandingSheffield path='/sheffield' />
            <LandingCambridge path='/cambridge' />
            <LandingLeeds path='/leeds' />
            <LandingNorthampton path='/northampton' />
            <LandingWolverhampton path='/wolverhampton' />
            <LandingCardiff path='/cardiff' />
            <LandingLeicester path='/leicester' />
            <LandingNorwich path='/norwich' />
            <LandingArchitect path='/architect' />
            <LandingBirminghamArchitect path='/birmingham/architect' />
            <LandingCoventryArchitect path='/coventry/architect' />
            <LandingLiverpoolArchitect path='/liverpool/architect' />
            <LandingNottinghamArchitect path='/nottingham/architect' />
            <LandingBoltonArchitect path='/bolton/architect' />
            <LandingDerbyArchitect path='/derby/architect' />
            <LandingLondonArchitect path='/london/architect' />
            <LandingPeterboroughArchitect path='/peterborough/architect' />
            <LandingBradfordArchitect path='/bradford/architect' />
            <LandingEdinburghArchitect path='/edinburgh/architect' />
            <LandingManchesterArchitect path='/manchester/architect' />
            <LandingReadingArchitect path='/reading/architect' />
            <LandingBristolArchitect path='/bristol/architect' />
            <LandingGlasgowArchitect path='/glasgow/architect' />
            <LandingNewcastleArchitect path='/newcastle/architect' />
            <LandingSheffieldArchitect path='/sheffield/architect' />
            <LandingCambridgeArchitect path='/cambridge/architect' />
            <LandingLeedsArchitect path='/leeds/architect' />
            <LandingNorthamptonArchitect path='/northampton/architect' />
            <LandingWolverhamptonArchitect path='/wolverhampton/architect' />
            <LandingCardiffArchitect path='/cardiff/architect' />
            <LandingLeicesterArchitect path='/leicester/architect' />
            <LandingNorwichArchitect path='/norwich/architect' />
            <LandingStructuralEngineer path='/structural%20engineer' />
            <LandingBirminghamStructuralEngineer path='/birmingham/structural%20engineer' />
            <LandingCoventryStructuralEngineer path='/coventry/structural%20engineer' />
            <LandingLiverpoolStructuralEngineer path='/liverpool/structural%20engineer' />
            <LandingNottinghamStructuralEngineer path='/nottingham/structural%20engineer' />
            <LandingBoltonStructuralEngineer path='/bolton/structural%20engineer' />
            <LandingDerbyStructuralEngineer path='/derby/structural%20engineer' />
            <LandingLondonStructuralEngineer path='/london/structural%20engineer' />
            <LandingPeterboroughStructuralEngineer path='/peterborough/structural%20engineer' />
            <LandingBradfordStructuralEngineer path='/bradford/structural%20engineer' />
            <LandingEdinburghStructuralEngineer path='/edinburgh/structural%20engineer' />
            <LandingManchesterStructuralEngineer path='/manchester/structural%20engineer' />
            <LandingReadingStructuralEngineer path='/reading/structural%20engineer' />
            <LandingBristolStructuralEngineer path='/bristol/structural%20engineer' />
            <LandingGlasgowStructuralEngineer path='/glasgow/structural%20engineer' />
            <LandingNewcastleStructuralEngineer path='/newcastle/structural%20engineer' />
            <LandingSheffieldStructuralEngineer path='/sheffield/structural%20engineer' />
            <LandingCambridgeStructuralEngineer path='/cambridge/structural%20engineer' />
            <LandingLeedsStructuralEngineer path='/leeds/structural%20engineer' />
            <LandingNorthamptonStructuralEngineer path='/northampton/structural%20engineer' />
            <LandingWolverhamptonStructuralEngineer path='/wolverhampton/structural%20engineer' />
            <LandingCardiffStructuralEngineer path='/cardiff/structural%20engineer' />
            <LandingLeicesterStructuralEngineer path='/leicester/structural%20engineer' />
            <LandingNorwichStructuralEngineer path='/norwich/structural%20engineer' />
            <LandingPropertySolicitor path='/property%20solicitor' />
            <LandingBirminghamPropertySolicitor path='/birmingham/property%20solicitor' />
            <LandingCoventryPropertySolicitor path='/coventry/property%20solicitor' />
            <LandingLiverpoolPropertySolicitor path='/liverpool/property%20solicitor' />
            <LandingNottinghamPropertySolicitor path='/nottingham/property%20solicitor' />
            <LandingBoltonPropertySolicitor path='/bolton/property%20solicitor' />
            <LandingDerbyPropertySolicitor path='/derby/property%20solicitor' />
            <LandingLondonPropertySolicitor path='/london/property%20solicitor' />
            <LandingPeterboroughPropertySolicitor path='/peterborough/property%20solicitor' />
            <LandingBradfordPropertySolicitor path='/bradford/property%20solicitor' />
            <LandingEdinburghPropertySolicitor path='/edinburgh/property%20solicitor' />
            <LandingManchesterPropertySolicitor path='/manchester/property%20solicitor' />
            <LandingReadingPropertySolicitor path='/reading/property%20solicitor' />
            <LandingBristolPropertySolicitor path='/bristol/property%20solicitor' />
            <LandingGlasgowPropertySolicitor path='/glasgow/property%20solicitor' />
            <LandingNewcastlePropertySolicitor path='/newcastle/property%20solicitor' />
            <LandingSheffieldPropertySolicitor path='/sheffield/property%20solicitor' />
            <LandingCambridgePropertySolicitor path='/cambridge/property%20solicitor' />
            <LandingLeedsPropertySolicitor path='/leeds/property%20solicitor' />
            <LandingNorthamptonPropertySolicitor path='/northampton/property%20solicitor' />
            <LandingWolverhamptonPropertySolicitor path='/wolverhampton/property%20solicitor' />
            <LandingCardiffPropertySolicitor path='/cardiff/property%20solicitor' />
            <LandingLeicesterPropertySolicitor path='/leicester/property%20solicitor' />
            <LandingNorwichPropertySolicitor path='/norwich/property%20solicitor' />
            <LandingPartyWallSurveyor path='/party%20wall%20surveyor' />
            <LandingBirminghamPartyWallSurveyor path='/birmingham/party%20wall%20surveyor' />
            <LandingCoventryPartyWallSurveyor path='/coventry/party%20wall%20surveyor' />
            <LandingLiverpoolPartyWallSurveyor path='/liverpool/party%20wall%20surveyor' />
            <LandingNottinghamPartyWallSurveyor path='/nottingham/party%20wall%20surveyor' />
            <LandingBoltonPartyWallSurveyor path='/bolton/party%20wall%20surveyor' />
            <LandingDerbyPartyWallSurveyor path='/derby/party%20wall%20surveyor' />
            <LandingLondonPartyWallSurveyor path='/london/party%20wall%20surveyor' />
            <LandingPeterboroughPartyWallSurveyor path='/peterborough/party%20wall%20surveyor' />
            <LandingBradfordPartyWallSurveyor path='/bradford/party%20wall%20surveyor' />
            <LandingEdinburghPartyWallSurveyor path='/edinburgh/party%20wall%20surveyor' />
            <LandingManchesterPartyWallSurveyor path='/manchester/party%20wall%20surveyor' />
            <LandingReadingPartyWallSurveyor path='/reading/party%20wall%20surveyor' />
            <LandingBristolPartyWallSurveyor path='/bristol/party%20wall%20surveyor' />
            <LandingGlasgowPartyWallSurveyor path='/glasgow/party%20wall%20surveyor' />
            <LandingNewcastlePartyWallSurveyor path='/newcastle/party%20wall%20surveyor' />
            <LandingSheffieldPartyWallSurveyor path='/sheffield/party%20wall%20surveyor' />
            <LandingCambridgePartyWallSurveyor path='/cambridge/party%20wall%20surveyor' />
            <LandingLeedsPartyWallSurveyor path='/leeds/party%20wall%20surveyor' />
            <LandingNorthamptonPartyWallSurveyor path='/northampton/party%20wall%20surveyor' />
            <LandingWolverhamptonPartyWallSurveyor path='/wolverhampton/party%20wall%20surveyor' />
            <LandingCardiffPartyWallSurveyor path='/cardiff/party%20wall%20surveyor' />
            <LandingLeicesterPartyWallSurveyor path='/leicester/party%20wall%20surveyor' />
            <LandingNorwichPartyWallSurveyor path='/norwich/party%20wall%20surveyor' />
            <LandingInteriorDesigner path='/interior%20designer' />
            <LandingBirminghamInteriorDesigner path='/birmingham/interior%20designer' />
            <LandingCoventryInteriorDesigner path='/coventry/interior%20designer' />
            <LandingLiverpoolInteriorDesigner path='/liverpool/interior%20designer' />
            <LandingNottinghamInteriorDesigner path='/nottingham/interior%20designer' />
            <LandingBoltonInteriorDesigner path='/bolton/interior%20designer' />
            <LandingDerbyInteriorDesigner path='/derby/interior%20designer' />
            <LandingLondonInteriorDesigner path='/london/interior%20designer' />
            <LandingPeterboroughInteriorDesigner path='/peterborough/interior%20designer' />
            <LandingBradfordInteriorDesigner path='/bradford/interior%20designer' />
            <LandingEdinburghInteriorDesigner path='/edinburgh/interior%20designer' />
            <LandingManchesterInteriorDesigner path='/manchester/interior%20designer' />
            <LandingReadingInteriorDesigner path='/reading/interior%20designer' />
            <LandingBristolInteriorDesigner path='/bristol/interior%20designer' />
            <LandingGlasgowInteriorDesigner path='/glasgow/interior%20designer' />
            <LandingNewcastleInteriorDesigner path='/newcastle/interior%20designer' />
            <LandingSheffieldInteriorDesigner path='/sheffield/interior%20designer' />
            <LandingCambridgeInteriorDesigner path='/cambridge/interior%20designer' />
            <LandingLeedsInteriorDesigner path='/leeds/interior%20designer' />
            <LandingNorthamptonInteriorDesigner path='/northampton/interior%20designer' />
            <LandingWolverhamptonInteriorDesigner path='/wolverhampton/interior%20designer' />
            <LandingCardiffInteriorDesigner path='/cardiff/interior%20designer' />
            <LandingLeicesterInteriorDesigner path='/leicester/interior%20designer' />
            <LandingNorwichInteriorDesigner path='/norwich/interior%20designer' />
            <LandingBuildingSurveyor path='/building%20surveyor' />
            <LandingBirminghamBuildingSurveyor path='/birmingham/building%20surveyor' />
            <LandingCoventryBuildingSurveyor path='/coventry/building%20surveyor' />
            <LandingLiverpoolBuildingSurveyor path='/liverpool/building%20surveyor' />
            <LandingNottinghamBuildingSurveyor path='/nottingham/building%20surveyor' />
            <LandingBoltonBuildingSurveyor path='/bolton/building%20surveyor' />
            <LandingDerbyBuildingSurveyor path='/derby/building%20surveyor' />
            <LandingLondonBuildingSurveyor path='/london/building%20surveyor' />
            <LandingPeterboroughBuildingSurveyor path='/peterborough/building%20surveyor' />
            <LandingBradfordBuildingSurveyor path='/bradford/building%20surveyor' />
            <LandingEdinburghBuildingSurveyor path='/edinburgh/building%20surveyor' />
            <LandingManchesterBuildingSurveyor path='/manchester/building%20surveyor' />
            <LandingReadingBuildingSurveyor path='/reading/building%20surveyor' />
            <LandingBristolBuildingSurveyor path='/bristol/building%20surveyor' />
            <LandingGlasgowBuildingSurveyor path='/glasgow/building%20surveyor' />
            <LandingNewcastleBuildingSurveyor path='/newcastle/building%20surveyor' />
            <LandingSheffieldBuildingSurveyor path='/sheffield/building%20surveyor' />
            <LandingCambridgeBuildingSurveyor path='/cambridge/building%20surveyor' />
            <LandingLeedsBuildingSurveyor path='/leeds/building%20surveyor' />
            <LandingNorthamptonBuildingSurveyor path='/northampton/building%20surveyor' />
            <LandingWolverhamptonBuildingSurveyor path='/wolverhampton/building%20surveyor' />
            <LandingCardiffBuildingSurveyor path='/cardiff/building%20surveyor' />
            <LandingLeicesterBuildingSurveyor path='/leicester/building%20surveyor' />
            <LandingNorwichBuildingSurveyor path='/norwich/building%20surveyor' />
            <LandingQuantitySurveyor path='/quantity%20surveyor' />
            <LandingBirminghamQuantitySurveyor path='/birmingham/quantity%20surveyor' />
            <LandingCoventryQuantitySurveyor path='/coventry/quantity%20surveyor' />
            <LandingLiverpoolQuantitySurveyor path='/liverpool/quantity%20surveyor' />
            <LandingNottinghamQuantitySurveyor path='/nottingham/quantity%20surveyor' />
            <LandingBoltonQuantitySurveyor path='/bolton/quantity%20surveyor' />
            <LandingDerbyQuantitySurveyor path='/derby/quantity%20surveyor' />
            <LandingLondonQuantitySurveyor path='/london/quantity%20surveyor' />
            <LandingPeterboroughQuantitySurveyor path='/peterborough/quantity%20surveyor' />
            <LandingBradfordQuantitySurveyor path='/bradford/quantity%20surveyor' />
            <LandingEdinburghQuantitySurveyor path='/edinburgh/quantity%20surveyor' />
            <LandingManchesterQuantitySurveyor path='/manchester/quantity%20surveyor' />
            <LandingReadingQuantitySurveyor path='/reading/quantity%20surveyor' />
            <LandingBristolQuantitySurveyor path='/bristol/quantity%20surveyor' />
            <LandingGlasgowQuantitySurveyor path='/glasgow/quantity%20surveyor' />
            <LandingNewcastleQuantitySurveyor path='/newcastle/quantity%20surveyor' />
            <LandingSheffieldQuantitySurveyor path='/sheffield/quantity%20surveyor' />
            <LandingCambridgeQuantitySurveyor path='/cambridge/quantity%20surveyor' />
            <LandingLeedsQuantitySurveyor path='/leeds/quantity%20surveyor' />
            <LandingNorthamptonQuantitySurveyor path='/northampton/quantity%20surveyor' />
            <LandingWolverhamptonQuantitySurveyor path='/wolverhampton/quantity%20surveyor' />
            <LandingCardiffQuantitySurveyor path='/cardiff/quantity%20surveyor' />
            <LandingLeicesterQuantitySurveyor path='/leicester/quantity%20surveyor' />
            <LandingNorwichQuantitySurveyor path='/norwich/quantity%20surveyor' />
            <LandingLandSurveyor path='/land%20surveyor' />
            <LandingBirminghamLandSurveyor path='/birmingham/land%20surveyor' />
            <LandingCoventryLandSurveyor path='/coventry/land%20surveyor' />
            <LandingLiverpoolLandSurveyor path='/liverpool/land%20surveyor' />
            <LandingNottinghamLandSurveyor path='/nottingham/land%20surveyor' />
            <LandingBoltonLandSurveyor path='/bolton/land%20surveyor' />
            <LandingDerbyLandSurveyor path='/derby/land%20surveyor' />
            <LandingLondonLandSurveyor path='/london/land%20surveyor' />
            <LandingPeterboroughLandSurveyor path='/peterborough/land%20surveyor' />
            <LandingBradfordLandSurveyor path='/bradford/land%20surveyor' />
            <LandingEdinburghLandSurveyor path='/edinburgh/land%20surveyor' />
            <LandingManchesterLandSurveyor path='/manchester/land%20surveyor' />
            <LandingReadingLandSurveyor path='/reading/land%20surveyor' />
            <LandingBristolLandSurveyor path='/bristol/land%20surveyor' />
            <LandingGlasgowLandSurveyor path='/glasgow/land%20surveyor' />
            <LandingNewcastleLandSurveyor path='/newcastle/land%20surveyor' />
            <LandingSheffieldLandSurveyor path='/sheffield/land%20surveyor' />
            <LandingCambridgeLandSurveyor path='/cambridge/land%20surveyor' />
            <LandingLeedsLandSurveyor path='/leeds/land%20surveyor' />
            <LandingNorthamptonLandSurveyor path='/northampton/land%20surveyor' />
            <LandingWolverhamptonLandSurveyor path='/wolverhampton/land%20surveyor' />
            <LandingCardiffLandSurveyor path='/cardiff/land%20surveyor' />
            <LandingLeicesterLandSurveyor path='/leicester/land%20surveyor' />
            <LandingNorwichLandSurveyor path='/norwich/land%20surveyor' />
            <LandingTownPlanner path='/town%20planner' />
            <LandingBirminghamTownPlanner path='/birmingham/town%20planner' />
            <LandingCoventryTownPlanner path='/coventry/town%20planner' />
            <LandingLiverpoolTownPlanner path='/liverpool/town%20planner' />
            <LandingNottinghamTownPlanner path='/nottingham/town%20planner' />
            <LandingBoltonTownPlanner path='/bolton/town%20planner' />
            <LandingDerbyTownPlanner path='/derby/town%20planner' />
            <LandingLondonTownPlanner path='/london/town%20planner' />
            <LandingPeterboroughTownPlanner path='/peterborough/town%20planner' />
            <LandingBradfordTownPlanner path='/bradford/town%20planner' />
            <LandingEdinburghTownPlanner path='/edinburgh/town%20planner' />
            <LandingManchesterTownPlanner path='/manchester/town%20planner' />
            <LandingReadingTownPlanner path='/reading/town%20planner' />
            <LandingBristolTownPlanner path='/bristol/town%20planner' />
            <LandingGlasgowTownPlanner path='/glasgow/town%20planner' />
            <LandingNewcastleTownPlanner path='/newcastle/town%20planner' />
            <LandingSheffieldTownPlanner path='/sheffield/town%20planner' />
            <LandingCambridgeTownPlanner path='/cambridge/town%20planner' />
            <LandingLeedsTownPlanner path='/leeds/town%20planner' />
            <LandingNorthamptonTownPlanner path='/northampton/town%20planner' />
            <LandingWolverhamptonTownPlanner path='/wolverhampton/town%20planner' />
            <LandingCardiffTownPlanner path='/cardiff/town%20planner' />
            <LandingLeicesterTownPlanner path='/leicester/town%20planner' />
            <LandingNorwichTownPlanner path='/norwich/town%20planner' />
            <LandingMainContractor path='/main%20contractor' />
            <LandingBirminghamMainContractor path='/birmingham/main%20contractor' />
            <LandingCoventryMainContractor path='/coventry/main%20contractor' />
            <LandingLiverpoolMainContractor path='/liverpool/main%20contractor' />
            <LandingNottinghamMainContractor path='/nottingham/main%20contractor' />
            <LandingBoltonMainContractor path='/bolton/main%20contractor' />
            <LandingDerbyMainContractor path='/derby/main%20contractor' />
            <LandingLondonMainContractor path='/london/main%20contractor' />
            <LandingPeterboroughMainContractor path='/peterborough/main%20contractor' />
            <LandingBradfordMainContractor path='/bradford/main%20contractor' />
            <LandingEdinburghMainContractor path='/edinburgh/main%20contractor' />
            <LandingManchesterMainContractor path='/manchester/main%20contractor' />
            <LandingReadingMainContractor path='/reading/main%20contractor' />
            <LandingBristolMainContractor path='/bristol/main%20contractor' />
            <LandingGlasgowMainContractor path='/glasgow/main%20contractor' />
            <LandingNewcastleMainContractor path='/newcastle/main%20contractor' />
            <LandingSheffieldMainContractor path='/sheffield/main%20contractor' />
            <LandingCambridgeMainContractor path='/cambridge/main%20contractor' />
            <LandingLeedsMainContractor path='/leeds/main%20contractor' />
            <LandingNorthamptonMainContractor path='/northampton/main%20contractor' />
            <LandingWolverhamptonMainContractor path='/wolverhampton/main%20contractor' />
            <LandingCardiffMainContractor path='/cardiff/main%20contractor' />
            <LandingLeicesterMainContractor path='/leicester/main%20contractor' />
            <LandingNorwichMainContractor path='/norwich/main%20contractor' />
            <LandingElectricalWorks path='/electrical%20works' />
            <LandingBirminghamElectricalWorks path='/birmingham/electrical%20works' />
            <LandingCoventryElectricalWorks path='/coventry/electrical%20works' />
            <LandingLiverpoolElectricalWorks path='/liverpool/electrical%20works' />
            <LandingNottinghamElectricalWorks path='/nottingham/electrical%20works' />
            <LandingBoltonElectricalWorks path='/bolton/electrical%20works' />
            <LandingDerbyElectricalWorks path='/derby/electrical%20works' />
            <LandingLondonElectricalWorks path='/london/electrical%20works' />
            <LandingPeterboroughElectricalWorks path='/peterborough/electrical%20works' />
            <LandingBradfordElectricalWorks path='/bradford/electrical%20works' />
            <LandingEdinburghElectricalWorks path='/edinburgh/electrical%20works' />
            <LandingManchesterElectricalWorks path='/manchester/electrical%20works' />
            <LandingReadingElectricalWorks path='/reading/electrical%20works' />
            <LandingBristolElectricalWorks path='/bristol/electrical%20works' />
            <LandingGlasgowElectricalWorks path='/glasgow/electrical%20works' />
            <LandingNewcastleElectricalWorks path='/newcastle/electrical%20works' />
            <LandingSheffieldElectricalWorks path='/sheffield/electrical%20works' />
            <LandingCambridgeElectricalWorks path='/cambridge/electrical%20works' />
            <LandingLeedsElectricalWorks path='/leeds/electrical%20works' />
            <LandingNorthamptonElectricalWorks path='/northampton/electrical%20works' />
            <LandingWolverhamptonElectricalWorks path='/wolverhampton/electrical%20works' />
            <LandingCardiffElectricalWorks path='/cardiff/electrical%20works' />
            <LandingLeicesterElectricalWorks path='/leicester/electrical%20works' />
            <LandingNorwichElectricalWorks path='/norwich/electrical%20works' />
            <LandingPlumbing path='/plumbing' />
            <LandingBirminghamPlumbing path='/birmingham/plumbing' />
            <LandingCoventryPlumbing path='/coventry/plumbing' />
            <LandingLiverpoolPlumbing path='/liverpool/plumbing' />
            <LandingNottinghamPlumbing path='/nottingham/plumbing' />
            <LandingBoltonPlumbing path='/bolton/plumbing' />
            <LandingDerbyPlumbing path='/derby/plumbing' />
            <LandingLondonPlumbing path='/london/plumbing' />
            <LandingPeterboroughPlumbing path='/peterborough/plumbing' />
            <LandingBradfordPlumbing path='/bradford/plumbing' />
            <LandingEdinburghPlumbing path='/edinburgh/plumbing' />
            <LandingManchesterPlumbing path='/manchester/plumbing' />
            <LandingReadingPlumbing path='/reading/plumbing' />
            <LandingBristolPlumbing path='/bristol/plumbing' />
            <LandingGlasgowPlumbing path='/glasgow/plumbing' />
            <LandingNewcastlePlumbing path='/newcastle/plumbing' />
            <LandingSheffieldPlumbing path='/sheffield/plumbing' />
            <LandingCambridgePlumbing path='/cambridge/plumbing' />
            <LandingLeedsPlumbing path='/leeds/plumbing' />
            <LandingNorthamptonPlumbing path='/northampton/plumbing' />
            <LandingWolverhamptonPlumbing path='/wolverhampton/plumbing' />
            <LandingCardiffPlumbing path='/cardiff/plumbing' />
            <LandingLeicesterPlumbing path='/leicester/plumbing' />
            <LandingNorwichPlumbing path='/norwich/plumbing' />
            <LandingInteriorDecorator path='/interior%20decorator' />
            <LandingBirminghamInteriorDecorator path='/birmingham/interior%20decorator' />
            <LandingCoventryInteriorDecorator path='/coventry/interior%20decorator' />
            <LandingLiverpoolInteriorDecorator path='/liverpool/interior%20decorator' />
            <LandingNottinghamInteriorDecorator path='/nottingham/interior%20decorator' />
            <LandingBoltonInteriorDecorator path='/bolton/interior%20decorator' />
            <LandingDerbyInteriorDecorator path='/derby/interior%20decorator' />
            <LandingLondonInteriorDecorator path='/london/interior%20decorator' />
            <LandingPeterboroughInteriorDecorator path='/peterborough/interior%20decorator' />
            <LandingBradfordInteriorDecorator path='/bradford/interior%20decorator' />
            <LandingEdinburghInteriorDecorator path='/edinburgh/interior%20decorator' />
            <LandingManchesterInteriorDecorator path='/manchester/interior%20decorator' />
            <LandingReadingInteriorDecorator path='/reading/interior%20decorator' />
            <LandingBristolInteriorDecorator path='/bristol/interior%20decorator' />
            <LandingGlasgowInteriorDecorator path='/glasgow/interior%20decorator' />
            <LandingNewcastleInteriorDecorator path='/newcastle/interior%20decorator' />
            <LandingSheffieldInteriorDecorator path='/sheffield/interior%20decorator' />
            <LandingCambridgeInteriorDecorator path='/cambridge/interior%20decorator' />
            <LandingLeedsInteriorDecorator path='/leeds/interior%20decorator' />
            <LandingNorthamptonInteriorDecorator path='/northampton/interior%20decorator' />
            <LandingWolverhamptonInteriorDecorator path='/wolverhampton/interior%20decorator' />
            <LandingCardiffInteriorDecorator path='/cardiff/interior%20decorator' />
            <LandingLeicesterInteriorDecorator path='/leicester/interior%20decorator' />
            <LandingNorwichInteriorDecorator path='/norwich/interior%20decorator' />
            <LandingSoilInvestigation path='/soil%20investigation' />
            <LandingBirminghamSoilInvestigation path='/birmingham/soil%20investigation' />
            <LandingCoventrySoilInvestigation path='/coventry/soil%20investigation' />
            <LandingLiverpoolSoilInvestigation path='/liverpool/soil%20investigation' />
            <LandingNottinghamSoilInvestigation path='/nottingham/soil%20investigation' />
            <LandingBoltonSoilInvestigation path='/bolton/soil%20investigation' />
            <LandingDerbySoilInvestigation path='/derby/soil%20investigation' />
            <LandingLondonSoilInvestigation path='/london/soil%20investigation' />
            <LandingPeterboroughSoilInvestigation path='/peterborough/soil%20investigation' />
            <LandingBradfordSoilInvestigation path='/bradford/soil%20investigation' />
            <LandingEdinburghSoilInvestigation path='/edinburgh/soil%20investigation' />
            <LandingManchesterSoilInvestigation path='/manchester/soil%20investigation' />
            <LandingReadingSoilInvestigation path='/reading/soil%20investigation' />
            <LandingBristolSoilInvestigation path='/bristol/soil%20investigation' />
            <LandingGlasgowSoilInvestigation path='/glasgow/soil%20investigation' />
            <LandingNewcastleSoilInvestigation path='/newcastle/soil%20investigation' />
            <LandingSheffieldSoilInvestigation path='/sheffield/soil%20investigation' />
            <LandingCambridgeSoilInvestigation path='/cambridge/soil%20investigation' />
            <LandingLeedsSoilInvestigation path='/leeds/soil%20investigation' />
            <LandingNorthamptonSoilInvestigation path='/northampton/soil%20investigation' />
            <LandingWolverhamptonSoilInvestigation path='/wolverhampton/soil%20investigation' />
            <LandingCardiffSoilInvestigation path='/cardiff/soil%20investigation' />
            <LandingLeicesterSoilInvestigation path='/leicester/soil%20investigation' />
            <LandingNorwichSoilInvestigation path='/norwich/soil%20investigation' />
            <LandingGroundworks path='/groundworks' />
            <LandingBirminghamGroundworks path='/birmingham/groundworks' />
            <LandingCoventryGroundworks path='/coventry/groundworks' />
            <LandingLiverpoolGroundworks path='/liverpool/groundworks' />
            <LandingNottinghamGroundworks path='/nottingham/groundworks' />
            <LandingBoltonGroundworks path='/bolton/groundworks' />
            <LandingDerbyGroundworks path='/derby/groundworks' />
            <LandingLondonGroundworks path='/london/groundworks' />
            <LandingPeterboroughGroundworks path='/peterborough/groundworks' />
            <LandingBradfordGroundworks path='/bradford/groundworks' />
            <LandingEdinburghGroundworks path='/edinburgh/groundworks' />
            <LandingManchesterGroundworks path='/manchester/groundworks' />
            <LandingReadingGroundworks path='/reading/groundworks' />
            <LandingBristolGroundworks path='/bristol/groundworks' />
            <LandingGlasgowGroundworks path='/glasgow/groundworks' />
            <LandingNewcastleGroundworks path='/newcastle/groundworks' />
            <LandingSheffieldGroundworks path='/sheffield/groundworks' />
            <LandingCambridgeGroundworks path='/cambridge/groundworks' />
            <LandingLeedsGroundworks path='/leeds/groundworks' />
            <LandingNorthamptonGroundworks path='/northampton/groundworks' />
            <LandingWolverhamptonGroundworks path='/wolverhampton/groundworks' />
            <LandingCardiffGroundworks path='/cardiff/groundworks' />
            <LandingLeicesterGroundworks path='/leicester/groundworks' />
            <LandingNorwichGroundworks path='/norwich/groundworks' />
            <LandingPillingContractors path='/pilling%20contractors' />
            <LandingBirminghamPillingContractors path='/birmingham/pilling%20contractors' />
            <LandingCoventryPillingContractors path='/coventry/pilling%20contractors' />
            <LandingLiverpoolPillingContractors path='/liverpool/pilling%20contractors' />
            <LandingNottinghamPillingContractors path='/nottingham/pilling%20contractors' />
            <LandingBoltonPillingContractors path='/bolton/pilling%20contractors' />
            <LandingDerbyPillingContractors path='/derby/pilling%20contractors' />
            <LandingLondonPillingContractors path='/london/pilling%20contractors' />
            <LandingPeterboroughPillingContractors path='/peterborough/pilling%20contractors' />
            <LandingBradfordPillingContractors path='/bradford/pilling%20contractors' />
            <LandingEdinburghPillingContractors path='/edinburgh/pilling%20contractors' />
            <LandingManchesterPillingContractors path='/manchester/pilling%20contractors' />
            <LandingReadingPillingContractors path='/reading/pilling%20contractors' />
            <LandingBristolPillingContractors path='/bristol/pilling%20contractors' />
            <LandingGlasgowPillingContractors path='/glasgow/pilling%20contractors' />
            <LandingNewcastlePillingContractors path='/newcastle/pilling%20contractors' />
            <LandingSheffieldPillingContractors path='/sheffield/pilling%20contractors' />
            <LandingCambridgePillingContractors path='/cambridge/pilling%20contractors' />
            <LandingLeedsPillingContractors path='/leeds/pilling%20contractors' />
            <LandingNorthamptonPillingContractors path='/northampton/pilling%20contractors' />
            <LandingWolverhamptonPillingContractors path='/wolverhampton/pilling%20contractors' />
            <LandingCardiffPillingContractors path='/cardiff/pilling%20contractors' />
            <LandingLeicesterPillingContractors path='/leicester/pilling%20contractors' />
            <LandingNorwichPillingContractors path='/norwich/pilling%20contractors' />
            <LandingCctvAndDrainageSurveys path='/cctv%20and%20drainage%20surveys' />
            <LandingBirminghamCctvAndDrainageSurveys path='/birmingham/cctv%20and%20drainage%20surveys' />
            <LandingCoventryCctvAndDrainageSurveys path='/coventry/cctv%20and%20drainage%20surveys' />
            <LandingLiverpoolCctvAndDrainageSurveys path='/liverpool/cctv%20and%20drainage%20surveys' />
            <LandingNottinghamCctvAndDrainageSurveys path='/nottingham/cctv%20and%20drainage%20surveys' />
            <LandingBoltonCctvAndDrainageSurveys path='/bolton/cctv%20and%20drainage%20surveys' />
            <LandingDerbyCctvAndDrainageSurveys path='/derby/cctv%20and%20drainage%20surveys' />
            <LandingLondonCctvAndDrainageSurveys path='/london/cctv%20and%20drainage%20surveys' />
            <LandingPeterboroughCctvAndDrainageSurveys path='/peterborough/cctv%20and%20drainage%20surveys' />
            <LandingBradfordCctvAndDrainageSurveys path='/bradford/cctv%20and%20drainage%20surveys' />
            <LandingEdinburghCctvAndDrainageSurveys path='/edinburgh/cctv%20and%20drainage%20surveys' />
            <LandingManchesterCctvAndDrainageSurveys path='/manchester/cctv%20and%20drainage%20surveys' />
            <LandingReadingCctvAndDrainageSurveys path='/reading/cctv%20and%20drainage%20surveys' />
            <LandingBristolCctvAndDrainageSurveys path='/bristol/cctv%20and%20drainage%20surveys' />
            <LandingGlasgowCctvAndDrainageSurveys path='/glasgow/cctv%20and%20drainage%20surveys' />
            <LandingNewcastleCctvAndDrainageSurveys path='/newcastle/cctv%20and%20drainage%20surveys' />
            <LandingSheffieldCctvAndDrainageSurveys path='/sheffield/cctv%20and%20drainage%20surveys' />
            <LandingCambridgeCctvAndDrainageSurveys path='/cambridge/cctv%20and%20drainage%20surveys' />
            <LandingLeedsCctvAndDrainageSurveys path='/leeds/cctv%20and%20drainage%20surveys' />
            <LandingNorthamptonCctvAndDrainageSurveys path='/northampton/cctv%20and%20drainage%20surveys' />
            <LandingWolverhamptonCctvAndDrainageSurveys path='/wolverhampton/cctv%20and%20drainage%20surveys' />
            <LandingCardiffCctvAndDrainageSurveys path='/cardiff/cctv%20and%20drainage%20surveys' />
            <LandingLeicesterCctvAndDrainageSurveys path='/leicester/cctv%20and%20drainage%20surveys' />
            <LandingNorwichCctvAndDrainageSurveys path='/norwich/cctv%20and%20drainage%20surveys' />
            <LandingKitchenDesigner path='/kitchen%20designer' />
            <LandingBirminghamKitchenDesigner path='/birmingham/kitchen%20designer' />
            <LandingCoventryKitchenDesigner path='/coventry/kitchen%20designer' />
            <LandingLiverpoolKitchenDesigner path='/liverpool/kitchen%20designer' />
            <LandingNottinghamKitchenDesigner path='/nottingham/kitchen%20designer' />
            <LandingBoltonKitchenDesigner path='/bolton/kitchen%20designer' />
            <LandingDerbyKitchenDesigner path='/derby/kitchen%20designer' />
            <LandingLondonKitchenDesigner path='/london/kitchen%20designer' />
            <LandingPeterboroughKitchenDesigner path='/peterborough/kitchen%20designer' />
            <LandingBradfordKitchenDesigner path='/bradford/kitchen%20designer' />
            <LandingEdinburghKitchenDesigner path='/edinburgh/kitchen%20designer' />
            <LandingManchesterKitchenDesigner path='/manchester/kitchen%20designer' />
            <LandingReadingKitchenDesigner path='/reading/kitchen%20designer' />
            <LandingBristolKitchenDesigner path='/bristol/kitchen%20designer' />
            <LandingGlasgowKitchenDesigner path='/glasgow/kitchen%20designer' />
            <LandingNewcastleKitchenDesigner path='/newcastle/kitchen%20designer' />
            <LandingSheffieldKitchenDesigner path='/sheffield/kitchen%20designer' />
            <LandingCambridgeKitchenDesigner path='/cambridge/kitchen%20designer' />
            <LandingLeedsKitchenDesigner path='/leeds/kitchen%20designer' />
            <LandingNorthamptonKitchenDesigner path='/northampton/kitchen%20designer' />
            <LandingWolverhamptonKitchenDesigner path='/wolverhampton/kitchen%20designer' />
            <LandingCardiffKitchenDesigner path='/cardiff/kitchen%20designer' />
            <LandingLeicesterKitchenDesigner path='/leicester/kitchen%20designer' />
            <LandingNorwichKitchenDesigner path='/norwich/kitchen%20designer' />
            <LandingLandscapingServices path='/landscaping%20services' />
            <LandingBirminghamLandscapingServices path='/birmingham/landscaping%20services' />
            <LandingCoventryLandscapingServices path='/coventry/landscaping%20services' />
            <LandingLiverpoolLandscapingServices path='/liverpool/landscaping%20services' />
            <LandingNottinghamLandscapingServices path='/nottingham/landscaping%20services' />
            <LandingBoltonLandscapingServices path='/bolton/landscaping%20services' />
            <LandingDerbyLandscapingServices path='/derby/landscaping%20services' />
            <LandingLondonLandscapingServices path='/london/landscaping%20services' />
            <LandingPeterboroughLandscapingServices path='/peterborough/landscaping%20services' />
            <LandingBradfordLandscapingServices path='/bradford/landscaping%20services' />
            <LandingEdinburghLandscapingServices path='/edinburgh/landscaping%20services' />
            <LandingManchesterLandscapingServices path='/manchester/landscaping%20services' />
            <LandingReadingLandscapingServices path='/reading/landscaping%20services' />
            <LandingBristolLandscapingServices path='/bristol/landscaping%20services' />
            <LandingGlasgowLandscapingServices path='/glasgow/landscaping%20services' />
            <LandingNewcastleLandscapingServices path='/newcastle/landscaping%20services' />
            <LandingSheffieldLandscapingServices path='/sheffield/landscaping%20services' />
            <LandingCambridgeLandscapingServices path='/cambridge/landscaping%20services' />
            <LandingLeedsLandscapingServices path='/leeds/landscaping%20services' />
            <LandingNorthamptonLandscapingServices path='/northampton/landscaping%20services' />
            <LandingWolverhamptonLandscapingServices path='/wolverhampton/landscaping%20services' />
            <LandingCardiffLandscapingServices path='/cardiff/landscaping%20services' />
            <LandingLeicesterLandscapingServices path='/leicester/landscaping%20services' />
            <LandingNorwichLandscapingServices path='/norwich/landscaping%20services' />

          {/* End of routing components */}
          {/* Sitemap pages */}
          <SignIn path='/sign-in' />
          <SignUp path='/sign-up' />
          <LearnMore path='/the-platform' />
          <Contact path='/contact' />
          <Appointment path='/appointment' />
          <NewLanding path='/find-an-expert' />
          <ExpertsLanding path='/join-as-expert' />
          <Pricing path='/pricing' />
          <Faq path='/faq' />
          <FloorPlans path='/floor-plans' />
          {/* End of Sitemap pages */}

          <Welcome path='/welcome' />
          <WelcomeExpert path='/welcome-expert' />
          <Cookies path='/cookies-policy' />
          <TermsAndConditions path='/terms-and-conditions' />
          <PrivacyPolicy path='/privacy-policy' />
          <FourOFour default />
          <Finished path='/finished' />
          <Unsubscribed path='/email/unsubscribe/:email' />
          <ResetPassword path='/businesses/resetPassword/:id/:token' />
          <EmailVerified path='/businesses/verify/:token' />
          <Dashboard path='/dashboard' />
          <Leads path='/leads' />
          <Areas path='/areas' />
          <Invoices path='/invoices' />
          <ConfirmationBilling path='/confirmation' />
          <MyAccount path='/my-account/' />
          <BillingSettings path='/billing-settings' />
          <ClaimLead path='/claim-lead/:token' />
          <LeadClaimed path='/lead-claimed' />
          <LeadAlreadyClaimed path='/lead-already-claimed' />
          <LeadNoLeadsLeft path='/lead-no-leads' />
          <LeadNoFreeLeadsLeft path='/lead-no-free-leads' />
          <LeadClaimError path='/lead-claim-error' />
          <DeleteProfile path='/delete-profile/:token' />
          <ClaimFreeLeads path='/claim-free-leads/:token' />
          <FreeLeadsClaimed path='/free-leads-claimed' />
          <FreeLeadsAlreadyClaimed path='/free-leads-already-claimed' />
          <FreeLeadsLeft path='/free-leads-left' />
          <FreeLeadsError path='/free-leads-error' />
          <NotInterestedClaim path='/feedback-claim-lead/:token' />
          <Profile path='/profile/:profileId' />
          <Settings path='/settings' />
          <AccountSuspended path='/account-suspended' />
        </Router>
      </main>
      <CookieConsent
        disableStyles
        overlay
        buttonClasses='transition ease-in-out delay-50 border-none outline-none rounded-full font-bold text-2xl text-white bg-gradient-to-r from-gradient-start to-gradient-stop p-5 mt-2 hover:-translate-y-1 hover:scale-110 duration-300'
        containerClasses='flex flex-col absolute z-[999] bg-white p-4 sm:rounded-lg sm:left-1/2 sm:top-1/2 sm:w-[340px] sm:h-[210px] sm:-ml-[170px] sm:-mt-[105px]'
        contentClasses='text-xl flex flex-col'
        buttonWrapperClasses='self-center'
        overlayClasses='fixed left-0 top-0 w-full h-full z-[999] bg-slate-600/75'
      >
        <p className='text-3xl font-bold text-center'>Before you continue to Expert Plans</p>
        <div className='flex flex-row mt-6'>
          <img src={cookies} alt='cookies' width='25%' className='mr-2 w-auto h-40' />
          <div>
            <p>
              This site uses cookies to deliver its services and to analyze traffic.
            </p>
            <p className='mt-4'>
              To find out more, read our updated
              <Link
                to='/privacy-policy'
                customCss={css`color:var(--darkColor);`}
              > privacy policy
              </Link>
              .
            </p>
          </div>
        </div>
      </CookieConsent>
    </Suspense>
  )
}

export default App
